.noChallengesLink {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-decoration: none;
  width: 190px;
  margin-top: 30px;
}
.subHeader {
  padding: 0.75rem 0;
}
.backButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--red);
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem 1rem 1.25rem;
  text-decoration: none;
}
.backIcon {
  margin-right: 0.25rem;
  width: 1.25rem;
}
.challenge {
  padding: 2rem 0;
}
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.content {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  padding: 1rem 0;
}
.challengeInfo {
  padding: 0 1rem 2rem;
}
.dates {
  line-height: 1.4em;
  margin-bottom: 2rem;
}
.heading {
  align-items: center;
  color: var(--heading);
  display: flex;
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.subHeading {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.list {
  margin-bottom: 2rem;
}
.item {
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.createTeamActions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.createTeamButton {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 700;
  margin-right: 0.75rem;
  padding: 1rem 1.5rem;
  text-decoration: none;
}
.createTeamText {
  font-size: 14px;
  line-height: 1.4em;
}
.createTeamLink {
  color: currentColor;
  font-weight: 700;
}
.teams {
  border-top: 1px solid var(--graySpecial);
  padding: 2rem 1rem;
}
.teamsHeading {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.teamsHeading span {
  font-family: "Ica Text Ny";
  font-weight: 400;
}
.teamsList {
  list-style: disc;
  padding: 0 1rem;
}
.teamsItem {
  padding: 0.5rem 0;
}
.teamsLink {
  color: currentColor;
}
.changeButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: var(--colorCtaSecondary);
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  margin-left: 1rem;
}
.challengeName {
  display: flex;
  align-items: center;
}
.editName {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.editNameInput {
  appearance: none;
  background-color: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: var(--heading);
  display: block;
  font-family: "ICA Rubrik";
  font-size: 1rem;
  line-height: 1.4em;
  max-width: 600px;
  min-width: 220px;
  outline: none;
  padding: 0 1rem;
  width: auto;
}
.saveNameButton {
  appearance: none;
  background-color: var(--bgCtaSecondary);
  border: 0;
  border-radius: 100px;
  color: var(--colorCtaSecondary);
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-left: 1rem;
  padding: 1rem 2rem;
}
.hidden {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.editQuestionInput {
  min-width: 150px;
}
@media (min-width: 60rem) {
  .heading {
    font-size: 2rem;
  }
  .editNameInput {
    font-size: 1.25rem;
    min-width: 220px;
  }
  .challenge {
    padding: 3rem 0;
  }
}
