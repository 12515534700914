.subHeader {
  padding: 0.75rem 0;
  margin-left: 17rem;
}
.backButton {
  align-items: center;
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: var(--red);
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem 1rem 1.25rem;
  text-decoration: none;
}
.backIcon {
  margin-right: 0.25rem;
  width: 1.25rem;
}
.headerButton {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-decoration: none;
}
.challenges {
  padding: 2rem 0;
}
.heading {
  color: var(--heading);
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.list {
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}
.link {
  align-items: center;
  background: #fff;
  border-bottom: 2px solid var(--graySpecial);
  color: currentColor;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.25rem 1rem;
}
.itemPeriod {
  font-size: 14px;
}
.item:last-child .link {
  border-bottom: 0;
}
.loading,
.noChallenges {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.noChallengesText {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.noChallengesLink {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  padding: 1rem 1.5rem;
  text-decoration: none;
  width: 150px;
}

@media (min-width: 60rem) {
  .heading {
    font-size: 2rem;
    margin-bottom: 1em;
  }
  .noChallengesText {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
  .noChallengesLink {
    padding: 1.5rem 2rem;
    width: 180px;
  }
}
