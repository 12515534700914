.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}
.loginPage {
  composes: page;
  background-position: center;
  background-size: cover;
}
.loadingText {
  font-size: 1.25rem;
  font-weight: 700;
}
.content {
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 350px;
  padding: 1.25rem;
  text-align: center;
  width: 100%;
}
.heading {
  font-size: 1.5rem;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
.preamble {
  line-height: 1.2em;
  text-align: center;
  margin-bottom: 1.5rem;
}
.loginButton {
  appearance: none;
  background: var(--bgCta);
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Ica Text Ny";
  font-size: 14px;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  width: 100%;
}
.logo {
  display: block;
  margin-bottom: 1.5rem;
  max-width: 60px;
}
@media (min-width: 60rem) {
  .heading {
    font-size: 2rem;
  }
  .loginPage {
    background-position: center;
  }
}
